
















import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { MyPageViewModel } from '../../viewmodels/my-page-viewmodel'

@Observer
@Component({
  components: {
    'dao-to-join-item': () => import('@/modules/community/home/components/discovery/dao-to-join-item.vue'),
    observer: () => import('vue-intersection-observer'),
  },
})
export default class DaoToJoinList extends Vue {
  @Inject({}) vm!: MyPageViewModel

  loadMore(entry) {
    if (this.vm.daosCanLoadMore) {
      if (entry.isIntersecting) this.vm.daosLoadMore()
    }
  }
}
